import React from "react";
import styled from "styled-components";
import Link from "next/link";

import LinkButton from "../styled/LinkButton";

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #FFFFFF;

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 960px;

    padding-left: 15px;
    padding-right: 15px;

    .footer-nav {
      font-family: 'MerriweatherLight';
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      text-align: center;
      color: #424242;

      span, a {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        color: #424242;
      }
    }

    .footer-disclaimer {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 32px;
      padding-right: 32px;

      font-family: 'MerriweatherLight';
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      text-align: center;
      color: #424242;

      a {
        color: #424242;
      }
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <div className="footer-container">
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && process.env.NEXT_PUBLIC_BASE_PATH !== "/3" && process.env.NEXT_PUBLIC_BASE_PATH !== "/4" && process.env.NEXT_PUBLIC_BASE_PATH !== "/5" &&
          <div className="footer-nav">
            <span>© {new Date().getFullYear()} EIN TaxID USA, All Rights Reserved</span>
            {/* <Link href="/incorps/home"><a>Incorporate LLC</a></Link>| */}
            {/* <Link href="/entities"><a>Apply For EIN</a></Link>| */}
            <Link href="/"><a>Home</a></Link>|
            <Link href="/terms"><a>Terms and Conditions</a></Link>|
            <Link href="/pricing"><a>Services & Pricing</a></Link>|
            <Link href="/privacy"><a>Privacy Policy</a></Link>|
            <Link href="/faq"><a>FAQ</a></Link>|
            <Link href="/contactus"><a>Contact Us</a></Link>|
            <Link href="/status"><a>Order Status</a></Link>
          </div>
        }
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && process.env.NEXT_PUBLIC_BASE_PATH !== "/3" && process.env.NEXT_PUBLIC_BASE_PATH !== "/4" && process.env.NEXT_PUBLIC_BASE_PATH !== "/5" &&
          <div className="footer-disclaimer">
            <Link href="/"><a>{window?.location.hostname}</a></Link>&nbsp; is an application assistance and filing
            service that acts as a
            Third Party Designee to assist clients in obtaining Employer Identification Numbers (EIN) from the IRS.
            We are not affiliated with the Internal Revenue Service or any other federal or state organizations.
            We do not provide legal, financial or other professional advice.
            We are not a law or an accountancy firm nor are we affiliated with either.
            Users of this site may also visit <a href="http://www.irs.gov/" target="_blank"
                                                 style={{ color: "#8888" }}>http://www.irs.gov</a>&nbsp;
            if they wish to complete an EIN number application free of charge on their own,
            however they are not eligible for assistance or a simplified application process from us.
          </div>}
        {process.env.NEXT_PUBLIC_BASE_PATH === "/2" && <div className="footer-disclaimer">
            We are an authorized Internal Revenue Service (IRS) e-file Provider. We are a business-to-business (B2B) application assistance and filing service and are not affiliated with the IRS or any other federal or state organizations.<br/>
            © {new Date().getFullYear()} All Rights Reserved
          </div>
        }
        {process.env.NEXT_PUBLIC_BASE_PATH === "/3" && <div className="footer-disclaimer">
          We are a certified IRS e-file provider, offering business-to-business (B2B) application assistance and filing services. We operate independently and are not affiliated with the IRS or any other federal or state organizations.<br/>
          © {new Date().getFullYear()} All Rights Reserved
        </div>
        }
        {process.env.NEXT_PUBLIC_BASE_PATH === "/4" && <div className="footer-disclaimer">
          We are an accredited IRS e-file provider, specializing in business-to-business (B2B) application assistance and filing services. We operate as an independent entity and are not associated with the IRS or any other federal or state agencies.<br/>
          © {new Date().getFullYear()} All Rights Reserved
        </div>
        }
        {process.env.NEXT_PUBLIC_BASE_PATH === "/5" && <div className="footer-disclaimer">
          We are an accredited IRS e-file provider, specializing in business-to-business (B2B) application assistance and filing services. We operate as an independent entity and are not associated with the IRS or any other federal or state agencies.<br/>
          © {new Date().getFullYear()} All Rights Reserved
        </div>
        }
      </div>
    </StyledFooter>
  );
}

export default Footer;
