import React from "react";
import styled from "styled-components";

import NavBar from "../NavBar";

const StyledFooter = styled.div`
  border-top: 10px solid #078FF1;
`;

function Footer() {
  return (
    <StyledFooter>
      <NavBar />
    </StyledFooter>
  );
}

export default Footer;
