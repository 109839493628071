import React from "react";
import styled from "styled-components";
import Link from "next/link";

import LinkButton from "../styled/LinkButton";

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #FFFFFF;

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 960px;

    padding-left: 15px;
    padding-right: 15px;

    .footer-nav {
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      text-align: center;
      color: #4D4D4D;

      span, a {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        color: #4D4D4D;
      }
    }

    .footer-disclaimer {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 32px;
      padding-right: 32px;
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      text-align: center;
      color: #4D4D4D;

      a {
        color: #4D4D4D;
      }
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <div className="footer-container">
        <div className="footer-nav">
          <span>© {new Date().getFullYear()} EIN Federal Tax ID, All Rights Reserved</span>
          {/* <Link href="/incorps/home"><a>Incorporate LLC</a></Link>| */}
          {/* <Link href="/entities"><a>Apply For EIN</a></Link>| */}
          <Link href="/"><a>Home</a></Link>|
          <Link href="/terms"><a>Terms and Conditions</a></Link>|
          <Link href="/pricing"><a>Services & Pricing</a></Link>|
          <Link href="/privacy"><a>Privacy Policy</a></Link>|
          <Link href="/faq"><a>FAQ</a></Link>|
          <Link href="/contactus"><a>Contact Us</a></Link>|
          <Link href="/status"><a>Order Status</a></Link>
        </div>
        <div className="footer-disclaimer">
          <Link href="/"><a>{window?.location.hostname}</a></Link>&nbsp; is an application assistance and filing service that acts as a 
          Third Party Designee to assist clients in obtaining Employer Identification Numbers (EIN) from the IRS.  
          We are not affiliated with the Internal Revenue Service or any other federal or state organizations.  
          We do not provide legal, financial or other professional advice. 
          We are not a law or an accountancy firm nor are we affiliated with either. 
          Users of this site may also visit  <a href="http://www.irs.gov/" target="_blank" style={{ color: "#8888"}}>http://www.irs.gov</a>&nbsp;
          if they wish to complete an EIN number application free of charge on their own, 
          however they are not eligible for assistance or a simplified application process from us.
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
