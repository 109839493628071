import React from "react";
import styled from "styled-components";

import NavBar from "../NavBar";

const StyledHeader = styled.div`
  border-bottom: 20px solid #078FF1;
`;

function Header() {
  return (
    <StyledHeader>
      <NavBar withBurger />
    </StyledHeader>
  );
}

export default Header;
