import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.devices.tablet} {
    .footer-disclaimer {
      padding-bottom: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
    }
  }
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 52px;
  padding-right: 52px;

  .header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;

    .header-logo-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header-logo-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .header-logo-text {
        font-family: Platypi;
        font-weight: 700;
        font-size: 36px;
        line-height: 41px;
        color: #000000;
      }

      .header-logo-subtext {
        font-family: Platypi;
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;
        color: #000000;
      }
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;

    .header-left {
      gap: 8px;

      .header-logo-image {
        img {
          height: 32px;
        }
      }

      .header-logo-title {
        .header-logo-text {
          font-size: 16px;
          line-height: 20px;
        }

        .header-logo-subtext {
          display: none;
        }
      }
    }

    .header-right {
      gap: 8px;

      .header-top-firm-badge {
        img {
          height: 40px;
        }
      }

      .header-e-file-badge {
        padding-left: 8px;
        padding-right: 8px;

        img {
          height: 40px;
        }
      }
    }
  }
`;

function Footer() {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="header-left">
          <div className="header-logo-image">
            <picture>
              <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/logo.svg`} type="image/png" />
              <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/logo.svg`} alt="Logo" />
            </picture>
          </div>
          <div className="header-logo-title">
            <div className="header-logo-text">US Tax Filing</div>
            <div className="header-logo-subtext">US Online Tax Prep & Document Filing</div>
          </div>
        </div>
        <div className="header-right">
          <div className="header-e-file-badge">
            <picture>
              <source
                srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/irs-e-file-provider.png`}
                type="image/png"
              />
              <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/irs-e-file-provider.png`} alt="Logo" />
            </picture>
          </div>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
}

export default Footer;
