import { GET_PRODUCTS } from "../types";

const initialState = {
  products: [],
  loading: true,
  order: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case "ORDER_STARTED":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "ORDER_SUCCESS":
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    case "ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "CLEAR_ERROR": {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    default:
      return state;
  }
}
