import React, { useState } from "react";
import styled from "styled-components";

import Link from "next/link";

import Logo from "../Logo";

const StyledNavMenu = styled.nav`
  display: flex;
  justify-content: space-between;

  max-width: 1110px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  background-color: #101661;

  .navbar-menu {
    display: flex;
    justify-content: flex-end;

    .navbar-menu-item {
      margin-left: 16px;

      a {
        font-family: 'Catamaran', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
  }

  .navbar-toggler {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;

    .navbar-menu {
      display: none;
    }

  }
`;

function NavMenu() {
  const [open, setOpen] = useState(false);

  return (
    <StyledNavMenu className="navbar navbar-expand-sm navbar-dark">
      <div className="navbar-brand">
        <Link href="/">
          <Logo />
        </Link>
      </div>

      <div className="navbar-menu">
        <div className="navbar-menu-item">
          <Link href="/products/INCLLC">
            Incorporate LLC
          </Link>
        </div>
        <div className="navbar-menu-item">
          <Link href="/">
            EIN Application
          </Link>
        </div>
      </div>

      <button
        className={!open ? "navbar-toggler collapsed" : "navbar-toggler"}
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded={open}
        aria-label="Toggle navigation"
        onClick={() => setOpen(!open)}
      >
        <span className="navbar-toggler-icon" />
      </button>

      {open && (
        <div className="collapse navbar-collapse " id="navbarContent">
          <ul className="navbar-nav mobile">
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/products/INCLLC"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Incorporate LLC
              </Link>
            </li>
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                EIN Application
              </Link>
            </li>
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/status"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Order status
              </Link>
            </li>
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/pricing"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/about"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                About
              </Link>
            </li>
            <li className="nav-item" onClick={() => setOpen(false)}>
              <Link
                href="/contactus"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </StyledNavMenu>
  );
}

export default NavMenu;
