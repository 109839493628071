const colors = {
  
};

const displaySize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const theme = {
  colors,
  devices: {
		mobileS: `(max-width: ${displaySize.mobileS})`,
    mobileM: `(max-width: ${displaySize.mobileM})`,
    mobileL: `(max-width: ${displaySize.mobileL})`,
		tablet: `(max-width: ${displaySize.tablet})`,
		laptopS: `(max-width: ${displaySize.laptopS})`,
    laptopM: `(max-width: ${displaySize.laptopM})`,
    laptopL: `(max-width: ${displaySize.laptopL})`,
    desktop: `(max-width: ${displaySize.desktop})`,
	},
};
