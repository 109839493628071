const initialState = {
  bucket: null,
  delayed: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case "REFRESH_UPSELL_BUCKET": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
