import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { Seo } from "../../../../component/SEO";
import { Favicons } from "../../../../component/Favicons";
import Header from "../Header";
import Footer from "../Footer";

const StyledLayout = styled.div`
	margin: 0 auto;
	background-color: #fff;
	box-shadow: 0 0 4px 1px rgba(30, 30, 30, .1);
	@media (min-width: 1280px) {
		width: 1170px;
	}
	@media (min-width: 1600px) {
			width: 1440px;
	}
`;

function Layout({ children }) {
	const router = useRouter();

	return (
		<StyledLayout>
      <Seo title="Ein Docs Online | Home" description="" />
			<Favicons />
      <Header
				showIncorporateLLCButton={!router.asPath?.includes("/products")}
			/>
			{children}
      <Footer />
		</StyledLayout>
		
	);
}

export default Layout;
