import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { Seo } from "../../../../component/SEO";
import Header from "../Header";
import Footer from "../Footer";

const StyledLayout = styled.div``;

function Layout({ children }) {
	const router = useRouter();

	return (
		<StyledLayout>
      <Seo title="Federal Tax ID | Home" description="" />
      <Header
				showIncorporateLLCButton={!router.asPath?.includes("/products")}
			/>
			{children}
      <Footer />
		</StyledLayout>
		
	);
}

export default Layout;
