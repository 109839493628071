import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

import { hexToRGBA } from '../../../../utils/theme';
import { THEME } from '../../../../theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: 'MerriweatherLight';
    line-height: 1.42857rem;

    .hintTooltip .tooltip-inner {
      background: white;
      border: 1px solid rgba(0,0,0,0.1);
      font-family: 'MerriweatherLight';
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      color: #424242;
    }
  }
`;
