import { combineReducers } from 'redux'
import productReducer from './productsReducer'
import orderReducer from "./orderReducer";
import upsellBucketReducer from './upsellBucketReducer';

export default combineReducers({
    products: productReducer,
    order: orderReducer,
    upsellBucket: upsellBucketReducer,
});
