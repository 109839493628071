import React from "react";
import Link from "next/link";
import { config } from "../../_config";

const { email } = config;
const HeaderContactUs = () => {
  return (
    <div className="header-contact-us" style={{ marginTop: 14 }}>
      {/* <Link href="/incorps/home">
        <a>INCORPORATE LLC</a>
      </Link>
      <br /> */}
      <a href={`mailto:${email}?subject=Mail from Contact Us USA Taxid Site`}>
        Questions? Click here to email an EIN Expert
      </a>
      <p>We are available Monday through Friday from 7AM - 10PM EST.</p>
    </div>
  );
};

export default HeaderContactUs;
