import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  align-items: start;
  background: #FF8800CC;
  font-family: Aeonik;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  line-height: 20.52px;
  text-align: left;
  padding-top: 8px;
  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 9px;
    .hideAlert {
      display: none;
    }
  }
`;
const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(360deg, #094B88 12.5%, #094B88 19.5%, #1084EE 72.5%);
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 52px;
  padding-right: 52px;

  .header-left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    .header-logo-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header-logo-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .header-logo-text {
        font-family: NoticiaText;
        font-weight: 700;
        font-size: 36px;
        line-height: 41px;
        color: #ffffff;
      }

      .header-logo-subtext {
        font-family: NoticiaText;
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;
        color: #ffffff;
      }
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .header-left {
      gap: 8px;

      .header-logo-image {
        img {
          height: 32px;
        }
      }

      .header-logo-title {
        .header-logo-text {
          font-size: 16px;
          line-height: 20px;
        }

        .header-logo-subtext {
          display: none;
        }
      }
    }

    .header-right {
      gap: 8px;

      .header-top-firm-badge {
        img {
          height: 40px;
        }
      }

      .header-e-file-badge {
        padding-left: 8px;
        padding-right: 8px;

        img {
          height: 40px;
        }
      }
    }
  }
`;

function Header() {
  return (
    <React.Fragment>
      <StyleTopContainer>
        <div>
          <picture>
            <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/alert.svg`} type="image/png" />
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/alert.svg`} alt="Logo" />
          </picture>
        </div>
        <div>IMPORTANT: 2023 Extensions are due October 15th. File ASAP to avoid fees.</div>
        <div className="hideAlert">
          <picture>
            <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/alert.svg`} type="image/png" />
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/alert.svg`} alt="Logo" />
          </picture>
        </div>
      </StyleTopContainer>
      <StyleOuterContainer>
        <StyledInnerContainer>
          <div className="header-left">
            <div className="header-logo-image">
              <picture>
                <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/logo.svg`} type="image/png" />
                <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/logo.svg`} alt="Logo" />
              </picture>
            </div>
            <div className="header-logo-title">
              <div className="header-logo-text">US E-Tax Services</div>
              <div className="header-logo-subtext">Expert Filing Service for Anything Tax Related</div>
            </div>
          </div>
          <div className="header-right">
            <div className="header-e-file-badge">
              <picture>
                <source
                  srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/irs-e-file-provider.png`}
                  type="image/png"
                />
                <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-3/irs-e-file-provider.png`}
                     alt="Logo" />
              </picture>
            </div>
          </div>
        </StyledInnerContainer>
      </StyleOuterContainer>
    </React.Fragment>
  );
}

export default Header;
