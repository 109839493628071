import React from "react";
import styled, { css } from "styled-components";
import NavMenu from "../NavMenu";

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: #101661;
`;

function Header() {
  return (
    <StyledHeader>
      <NavMenu />
    </StyledHeader>
  );
}

export default Header;
