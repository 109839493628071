import { createStore, applyMiddleware } from "redux";
import { createWrapper } from "next-redux-wrapper";
import thunk from "redux-thunk";
import rootReducers from "./reducers";

// create a store function
const store = (context) => createStore(rootReducers, applyMiddleware(thunk));

// export an assembled wrapper
const makeStore = createWrapper(store);
export default makeStore;
