import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import getConfig from "next/config";

import { hexToRGBA } from "../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledImg = styled.img`
  display: ${({ show }) => show ? 'initial' : 'none'};
  cursor: pointer;
`;

const StyledSider = styled.div`
  display: flex;
  position: fixed;

  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 80%;
  max-width: 100%;

  transform: translateX(-100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  background: #FFFFFF;

  z-index: 1000;

  ${({ show }) => show && css`
    transform: translateX(0);
  `};
`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  padding-left: 24px;
  padding-right: 24px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 67px;
    border-bottom: 1px solid ${hexToRGBA("#2D3947", 0.1)};

    font-family: MerriweatherLight;
    font-size: 16px;
    line-height: 30px;
    color: #1B3858;
  }
`;

function  MobileMenu() {
  const [show, setShow] = useState(false);

  const handleHide = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    document.addEventListener('click', handleHide, true);
    return () => {
      document.removeEventListener('click', handleHide, true);
    };
  }, []);
   
  return (      
    <>       
      <StyledImg 
        src={`${publicRuntimeConfig.basePath}/icons/V2/close_square_icon.svg`}
        alt=""  
        layout="fill"
        show={show}
        onClick={handleHide}
      />

      <StyledImg
        src={`${publicRuntimeConfig.basePath}/icons/V2/hamburger_icon.svg`}
        alt=""
        layout="fill"
        show={!show}
        onClick={handleShow}
      />       
      
      <StyledSider show={show}>            
        <StyledMenu onClick={handleHide}>
          {/* <Link href="/"><a>Home</a></Link> */}
          {/* <Link href="/terms"><a>Terms and Conditions</a></Link> */}
          {/* <Link href="/pricing"><a>Services &#38; Pricing</a></Link> */}
          {/* <Link href="/privacy"><a>Privacy Policy</a></Link> */}
          {/* <Link href="/about"><a>About</a></Link> */}
          {/* <Link href="/incorps/home"><a>Incorporate LLC</a></Link> */}
          <Link href="/contactus"><a>Contact Us</a></Link>
          <Link href="/status"><a>Order Status</a></Link>
        </StyledMenu>
      </StyledSider>
    </>
  );
};

export default MobileMenu;
