import React from "react";
import styled from "styled-components";

import { Seo } from "../../../../component/SEO";
import { Favicons } from "../../../../component/Favicons";
import Header from "../Header";
import Footer from "../../../../component/Footer";


const StyledLayout = styled.main``;

function Layout({ children }) {

	return (
		<StyledLayout className="irs">
      <Seo title="Incorporate LLC | Home" description="" />
			<Favicons />
      <Header />
			{children}
      <Footer />
		</StyledLayout>
		
	);
}

export default Layout;
