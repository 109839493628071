import React from "react";
import styled from "styled-components";
import Link from "next/link";

const StyledFooter = styled.div`
  font-family: RobotoRegular, sans-serif;
  color: #39393d;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-nav {
      margin-top: 20px;
      padding: 1rem 15px;
      color: #39393d;
      background-color: #f8f9fa;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      span {
        font-family: 'RobotoBold';
        min-width: 110px;
      }
      span, a {
        display: inline-block;
      }

      a {
        margin-left: 10px;
        margin-right: 10px;
        text-decoration: none;
        font-weight: 400;
        color: #055393;
        min-width: auto;
      }
      @media (min-width: 768px) {
          width: 750px;
      }
      @media (min-width: 992px) {
          width: 970px;
      }
      @media (min-width: 1024px) {
          width: 990px;
      }
      @media (min-width: 1200px) {
          width: 1170px;
      }
      @media (min-width: 1280px) {
          padding-left: 40px;
          padding-right: 40px;
          width: 1170px;
      }
      @media (min-width: 1600px) {
        padding-left: 115px;
        padding-right: 115px;
        width: 1440px;
      }
    }

    .footer-disclaimer {
      font-weight: 400;
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: calc(1.42857rem * (12 / 16));
      text-align: center;
      color: #424242;
      b {
        font-family: 'RobotoBold';
      }
      a {
        color: #424242;
      }
      @media (min-width: 768px) {
        width: 750px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1024px) {
        width: 990px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      @media (min-width: 1280px) {
        padding-left: 40px;
        padding-right: 40px;
        width: 1170px;
      }
      @media (min-width: 1600px) {
        padding-left: 115px;
        padding-right: 115px;
        width: 1440px;
      }
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <div className="footer-container">
          <div className="footer-nav">
            <span>© {new Date().getFullYear()} Ein Docs</span>
            <div>
            {/* <Link href="/incorps/home"><a>Incorporate LLC</a></Link>| */}
            {/* <Link href="/entities"><a>Apply For EIN</a></Link>| */}
            <Link href="/"><a>Start Application</a></Link>|
            <Link href="/pricing"><a>Services & Pricing</a></Link>|
            <Link href="/faq"><a>FAQ</a></Link>|
            <Link href="/status"><a>Order Status</a></Link>|
            <Link href="/contactus"><a>Contact Us</a></Link>|
            <Link href="/privacy"><a>Privacy Policy</a></Link>|
            <Link href="/terms"><a>Terms of Service</a></Link>
            </div>
          </div>
          <div className="footer-disclaimer">
            <b>Disclaimer</b>: We act as a Third Party Designee to professionally help
              businesses and consumers obtain tax ID numbers to be used in their line of business. We are not affiliated
              with the United States Internal Revenue Service (IRS) and acts as a separate entity on your behalf to
              simplify this whole process.
          </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
