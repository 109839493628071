import styled from 'styled-components';

const LinkButton = styled.a`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #0071BC;
  background-color: #0071BC;
  padding: 0.375rem 0.75rem !important;
  font-family: 'RobotoBold';
  font-size: 1rem;
  line-height: 1.42857;
  border-radius: 0.25rem;
  color: #FFFFFF;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default LinkButton;
