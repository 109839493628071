import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  color: white;
  img {
    margin-top: ${skin === "irsein" ? "-14px": "-24px"};
    cursor: pointer;
  }

  .logo-title  {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: center;

    .logo-text  {
      font-size: 28px;
      line-height: 28px;
      font-weight: 600;
    }

    .logo-subtext  {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 4px;

    img {
      margin-top: 0;
      max-width: 95%;
    }

    .logo-title  {
      .logo-text  {
        font-size: 22px;
        line-height: 22px;
      }

      .logo-subtext  {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

const Logo = () => {

  return (
    <StyledLogo>
      <img
        src={`${publicRuntimeConfig.basePath}/images/ein-taxid-logo.png`}
        alt="Logo"
      />
      <span className="logo-title">
        <span className="logo-text">
          EIN APPLICATION (2024)
        </span>
        <span className="logo-subtext">
          EIN PROCESSING & FILING SERVICE
        </span>
      </span>
    </StyledLogo>
  );
};

export default Logo;
