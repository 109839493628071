import React from "react";
import styled from "styled-components";

import { Seo } from "../../../component/SEO";
import Header from "../Header";
import Footer from "../Footer";

const StyledLayout = styled.div``;

function Layout({ children }) {

	return (
		<StyledLayout>
      <Seo title="TaxNumber | Home" description="" />
      <Header />
			{children}
      <Footer />
		</StyledLayout>
		
	);
}

export default Layout;
