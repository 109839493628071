import React from "react";
import  Link  from "next/link";
import { parseDomain, ParseResultType } from "parse-domain";

const Footer = () => {
  let domainName = "USA-taxID";
  let topLevelDomain = "com"
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    domainName = domain;
    topLevelDomain = topLevelDomains;
  }
  return (
    <div className="footer-wrapper">
      <div className="footer">        
        <div className="copyright">
          <p>© 2024 {domainName}</p>
        </div>
        <div className="links">
          <ul>
            {/* <li>
              <Link href="/incorps/home">Incorporate LLC</Link>
            </li> */}
            <li>
              <Link href="/status">Order status</Link>
            </li>
            <li>
              <Link href="/pricing">Pricing</Link>
            </li>
            <li>
              <Link href="/about">About</Link>
            </li>
            <li>
              <Link href="/contactus">Contact Us</Link>
            </li>

            <li>
              <Link href="/privacy">Privacy</Link>
            </li>
            <li>
              <Link href="/terms">Terms</Link>
            </li>
          </ul>
        </div>
        <hr />
        <div className="text">
          <h5>DISCLAIMER:</h5>
          <p>
            {domainName} acts as an Authorized e-File Provider as described in the instructions to Form SS-4 to help clients obtain Federal Tax ID Numbers from the Internal Revenue Service (the “IRS”) in a timely manner. It does not provide legal,
            financial, or professional advice. This website is not affiliated
            with the Internal Revenue Service or any governmental organization.
            You are able to obtain an EIN number yourself by working directly
            with the IRS, but they will not be able to assist you with the
            process or provide e-mail support.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
