import React, { useState } from "react";
import styled, { css } from "styled-components";
import Link from "next/link";

const StyledHeader = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #39393d;
  font-size: 16px;
  font-family: RobotoRegular, sans-serif;
  .hamburger {
    position: relative;
    flex: 0 0 auto;
    width: 33px;
    height: 23px;
    margin-right: 30px;
    display: none;
  }
  .hamburger hr {
    margin: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #39393d;
    position: absolute;
    left: 50%;
    outline: none;
    border: none;
  }
  .hamburger hr:first-child {
    top: 0;
    transform: translate(-50%) rotate(0);
    transition: transform .1s linear, top .1s linear .1s;
  }
  .hamburger hr:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(0);
    opacity: 1;
    transition: opacity .1s linear 0s;
  }
  .hamburger hr:last-child {
    top: 100%;
    transform: translate(-50%, -100%) rotate(0);
    transition: transform .1s linear, top .1s linear .1s;
  }
  .hamburger.active hr:first-child {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: top .1s linear, transform .1s linear .1s;
  }
  .hamburger.active hr:nth-child(2) {
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0);
    transition: opacity .1s linear 0s;
  }
  .hamburger.active hr:last-child {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: top .1s linear, transform .1s linear .1s;
  }
  .header_new {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hideMenu {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    .header__menu-link, .header__menu li {
      display: inline-block;
      font-size: 16px;
    }
    .header__menu-link {
      margin-right: 30px;
      color: #797c7f;
    }
    .header__menu-link--status {
      margin-right: 0;
      color: #0097e6;
      font-size: 14px;
      border: 1px solid #0097e6;
      border-radius: 8px;
      padding: 6px 26px;
      flex: 0 0 auto;
    }
    .header__menu-link:active, .header__menu-link:hover {
      color: #0097e6;
      text-decoration: none;
    }
    .header__menu-link--status:active, .header__menu-link--status:hover {
      color: #055393;
      text-decoration: none;
      border-color: #055393;
    }
  }
  @media (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .header_new {
      .hideMenu {
        transform: translateX(-180%);
        transition: .1s linear;
      }
      .hideMenu.active {
        transform: translateX(0);
        transition: .1s linear;
      }
    }
  }
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1024px) {
    width: 990px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
    width: 1170px;
  }
  @media (min-width: 1600px) {
      padding-left: 115px;
      padding-right: 115px;
      width: 1440px;
  }
`;

function Header({ showHomeView, showOrderStatusButton, showIncorporateLLCButton }) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <StyledHeader showHomeView={showHomeView}>
      <div className="header_new">
        <div className={isActive ? 'hamburger active': "hamburger"}
             onClick={toggleClass}>
          <hr />
          <hr />
          <hr />
        </div>
        <div className={isActive ? 'hideMenu active': "hideMenu"}>
          <ul className="header__menu">
            <li><Link href="/"><a className="header__menu-link">Apply For EIN</a></Link></li>
            <li><Link href="/faq"><a className="header__menu-link">FAQ</a></Link></li>
            <li><Link href="/contactus"><a className="header__menu-link">Contact Us</a></Link></li>
            <li><Link href="/status"><a className="header__menu-link" >Order Status</a></Link></li>
          </ul>
        </div>
        <Link href="/status"><a className="header__menu-link header__menu-link--status" >Order Status</a></Link>
      </div>
    </StyledHeader>
  );
}

export default Header;
