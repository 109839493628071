import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

import { hexToRGBA } from '../../../../utils/theme';
import { THEME } from '../../../../theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: 'RobotoRegular';
    line-height: 1.42857rem;
  }
`;
