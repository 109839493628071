import React from "react";
import Navbar from "./navbar";

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="header">
        <Navbar />
      </div>
    </div>
  );
};

export default Header;
