import React from "react";
import styled from "styled-components";

import { Seo } from "../../../../component/SEO";
import { Favicons } from "../../../../component/Favicons";
import Header from "../Header";
import Footer from "../Footer";

const StyledLayout = styled.div``;

function Layout({ children }) {
  return (
    <StyledLayout>
      <Seo
        title="EIN Application"
        description="IRS Authorized Electronic Return Originator. Small Business Online Tax Filing."
      />
      <Favicons />
      <Header />
      {children}
      <Footer />
    </StyledLayout>
  );
}

export default Layout;
